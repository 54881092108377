import { secondsInHour, secondsInMinute } from 'date-fns';
import { evaluate } from 'mathjs';
import { UNITS } from 'my-phr/components/result/const';
import { AUTO_OPEN_MODAL_PARAM_NAME } from 'my-phr/const';

export function toFixedDecimalIfNumber(value, numbersAfterDecimal = 2) {
  if (['undefined', 'object', 'boolean'].includes(typeof value)) {
    return Number(0).toFixed(numbersAfterDecimal);
  }
  if (isNaN(value)) {
    return value;
  }

  const numberValue = Number(value);

  if (!isFinite(numberValue)) {
    return null;
  }

  const formattedNumber = numberValue.toLocaleString('en-US', {
    minimumFractionDigits: numbersAfterDecimal,
    maximumFractionDigits: numbersAfterDecimal,
  });

  return formattedNumber;
}

export function digitsAfterDecimal(num) {
  if (Number.isInteger(num)) {
    return 0;
  }

  const arr = num?.toString().split('.');

  return arr?.[1]?.length ?? 0;
}

const MAX_DIGITS_AFTER_DECIMAL = 3;
const FIXED_DIGITS_AFTER_DECIMAL = 2;

export function getRoundedValue(value) {
  const valueDigitsAfterDecimal = digitsAfterDecimal(value);
  const roundedValue =
    valueDigitsAfterDecimal > MAX_DIGITS_AFTER_DECIMAL
      ? toFixedDecimalIfNumber(value, FIXED_DIGITS_AFTER_DECIMAL)
      : value;
  return roundedValue;
}

export const arbitraryUnitRegex = new RegExp(/\[iu\]/i);

export function determineRangeValue(
  numeratorValue,
  denominatorValue,
  rangeValue
) {
  for (let range of rangeValue) {
    if (Boolean(numeratorValue) && Boolean(denominatorValue)) {
      const parsedCondition = range.condition
        .replace(/{{ numerator.value }}/g, numeratorValue)
        .replace(/{{ denominator.value }}/g, denominatorValue);

      const [numerator, denominator] = parsedCondition.split(/\|\||&&/);

      const numResult = evaluate(numerator);
      const denomResult = evaluate(denominator);

      const result = parsedCondition.includes('||')
        ? numResult || denomResult
        : numResult && denomResult;

      if (Boolean(result)) {
        return range.value;
      }
    }
  }
}

export function findElementByStoryblockId(elements, storyblockId) {
  return elements?.find((item) => item.storyblockId === storyblockId);
}

export function getDisplayedValue(
  isDateTimeUnit,
  isTimeValue,
  dateTimeDisplay,
  timeDisplay,
  unitValueDisplay
) {
  if (isDateTimeUnit) {
    return dateTimeDisplay;
  }
  if (isTimeValue) {
    return timeDisplay;
  }
  return unitValueDisplay;
}

export function getValueInSeconds(value) {
  const hours = Math.floor(value / secondsInHour);
  const minutes = Math.floor((value % secondsInHour) / secondsInMinute);
  const seconds = (value % secondsInMinute).toFixed(0);

  return { hours, minutes, seconds };
}

export function removeTrailingZeroDecimal(value) {
  if (!value) return '';
  return Number(value).toString();
}

export const getAutoOpenModalType = () =>
  new URL(window.location.href).searchParams.get(AUTO_OPEN_MODAL_PARAM_NAME);

export const redirectBackToApp = (timeout = 3500) => {
  setTimeout(() => {
    window.location.href = 'com.storymdhealthapp://getback';
    window.close();
  }, timeout);
};

export function filterOutStringUnit(data) {
  if (!data) return [];
  return data.filter((item) => item?.unit !== UNITS.STRING);
}
